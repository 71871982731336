import React from 'react';
import instagramIcon from '../../assets/social-media-icons/instagram-icon.svg';
import tiktokIcon from '../../assets/social-media-icons/tiktok-icon.svg';
import youtubeIcon from '../../assets/social-media-icons/youtube-icon.svg';
import './style.css';

const Footer = () => {
  return (
    <footer className="bg-dark py-4 mt-auto">
      <div className="container">
        <div className="row">
          {/* Logo or Brand Name */}
          <div className="col-md-4 text-center mb-3 mb-md-0">
            <h5>Visoko.bg</h5>
            <p>
              Проект на{' '}
              <a href="https://visoko.bg/" target="_blank" rel="noreferrer">
                visoko.bg
              </a>
            </p>
          </div>

          {/* Navigation Links */}
          <div className="col-md-4 text-center mb-3 mb-md-0">
            <h5>Admin секция</h5>
            <ul className="list-unstyled">
              <li>
                <a href="/#/admin/login">Вход за администратори</a>
              </li>
            </ul>
          </div>

          {/* Social Media Icons */}
          <div className="col-md-4 text-center">
            <h5>Последвайте ни</h5>
            <div>
              <a href="https://www.instagram.com/visoko.bg" target="_blank" rel="noreferrer" className="me-3">
                <img className="social-media-icon" alt="instagram icon" src={instagramIcon} />
              </a>
              <a href="https://www.tiktok.com/@visoko.bg" target="_blank" rel="noreferrer" className="me-3">
                <img className="social-media-icon" alt="tiktok icon" src={tiktokIcon} />
              </a>
              <a href="https://www.youtube.com/@visokobg" target="_blank" rel="noreferrer">
                <img className="social-media-icon" alt="youtube icon" src={youtubeIcon} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
